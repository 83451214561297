:root {
    --header-height: 5rem;
    --content-height: calc(100vh - var(--header-height));
}

:root {
    --col-0: rgb(49, 48, 48);
    --col-1: rgb(52, 52, 52);
    --col-2: rgb(126, 126, 126);
    --col-3: rgb(91, 153, 189);
}

* {
    box-sizing: border-box;
}

    // &::-webkit-scrollbar {
    //     display: none;
    // }

body {
    margin: 0;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    height: 100%;
}

.App {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    height: 100%;

}


.Content {
    position: relative;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    width: 100%;
    background-color: var(--col-3);
}
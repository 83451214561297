.Skillboxlist {
    width: 100%;
    height: 100%;

    .Skillbox-container {
        // background-color: rgb(196, 196, 196);
        background-color: rgb(255, 255, 255);
        width: 100% ;
        max-width: 60rem;
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
        position: relative;
        // height: 100%;

        &:hover .Skillbox-popup {
            // height: 9rem;
            opacity: 1;
            transform: scaleX(1);
        }

        &:hover .Skillbox {
            border-bottom: 2px solid transparent;
        }
    }


    // .Skillbox-popup:hover .Skillbox-popup {
    //     display: none;
    // }


    .Skillbox-popup {
        // background-image: linear-gradient(rgb(62, 26, 180), rgb(56, 0, 241));
        background-color: rgb(255, 255, 255);
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        border-left: 2px solid black;
        position: absolute;
        transition: height 0.4s, opacity .60s, transform .4s;
        top: calc(6rem - 2px);
        width: 100%;
        transform: scaleX(0);
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        opacity: 0;
        padding-top: calc(1rem + 2px);

        &:hover {
            display: none;
        }



        .Popup-box {
            position: relative;
            display: flex;
            justify-content: flex-start;
            height: 3.3rem;
            padding: calc(.2rem);
            width: 100%;
            border-top: 1px solid black;
            // background-color: rgb(46, 111, 111);
            color: black;
            align-items: center;

            img {
                width: 10%;
                height: 90%;
            }

            .Popup-text {
                display: flex;
                align-items: center;
                padding-left: 1rem;
            }

            .Skillbox-meter {
                // height: 20%;
                width: 20%;
                margin-left: 1rem;
                flex-grow: 1;
                display: flex;
                align-items: center;
                overflow-x: hidden;
                position: relative;
                z-index: 1;
    
    
                .Skillbar {
                    // background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(4, 4, 4));
                    background-color: black;
                    height: 1rem;
                    transform: translateX(-2rem);
                    width: 70%;
                    border-radius: 1rem;
                    // animation: 1s ease-out 0s 1 language-load;
                }
            }
        }
    }

    .Skillbox {
        height: 6rem;
        display: flex;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        border-left: 2px solid black;
        padding: .3rem;
        position: relative;
        z-index: 0;
        transition: border-bottom 1s;

        // .Skillbox-icon {
        //     height: 90%;
        //     width: 18%;
        // }

        .Skillbox-meter {
            height: 100%;
            width: 20%;
            margin-left: 1rem;
            flex-grow: 1;
            display: flex;
            align-items: center;
            overflow-x: hidden;
            position: relative;
            z-index: 1;


            .Skillbar {
                // background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(4, 4, 4));
                background-color: black;
                height: 2rem;
                transform: translateX(-1rem);
                width: 70%;
                border-radius: 1rem;
                animation: 1s ease-out 0s 1 language-load;
            }
        }
    }
}


@keyframes language-load {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(-1rem);
    }
}
.Header-content {
    background-color: var(--col-1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    position: fixed;
    z-index: 1000;

    .Header-title {
        color: white;
        padding-left: 1rem;
        height: var(--header-height);
        display: flex;
        align-items: center;
        font-size: clamp(2rem, 2.5vw, 3rem);
    }

    .Hamburger {
        position: absolute;
        right: 1rem;
        height: var(--header-height);
        display: none;
        align-items: center;

        .toggle-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            color: white;
            background-color: transparent;
            border: none;

            * {
                height: 80%;
            }
        }
    }

    .navbar-links {
        display: flex;
        justify-content: space-between;
        min-width: 20%;

        a {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--col-0);
            color: white;
            width: 5rem;
            height: 3rem;
            text-decoration: none;
        }

    }
}

@media (max-width: 500px) {

    .Header-content {
        padding: 0 0;
        flex-direction: column;
        align-items: flex-start;

        .navbar-links {
            width: 100%;
            flex-direction: column;
        }

        .navbar-links.active {
            display: flex;
        }

        .navbar-links {
            display: none;
        }

        .navbar-links a {
            width: 100%;
        }

        .Hamburger {
            display: flex;
        }

    }
}
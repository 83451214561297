:root {
    --height-card: 23rem;
    --PC-animation-dur: .6s;


    --col-test-1: rgba(135, 206, 250, 0.738);
    --col-test-2: rgb(198, 109, 93);
}

.Projects {
    position: relative;
    overflow-x: hidden;
    // overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    // &::-webkit-scrollbar {
    //     display: none;
    // }



    
    @mixin align-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Project-intro {
        @include align-center();
        height: 100vh;
        width: 100%;
        background-color: tomato;
        position: relative;
        z-index: 20;
        font-size: clamp(2rem, 3vw, 6rem);

        &.intro-top {
            height: calc(100vh - var(--header-height));
            margin-bottom: 10rem;
            background-color: var(--col-test-1);


            .intro-top-triangle {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 5rem solid var(--col-test-1);
                border-right: 100vw solid transparent;
                bottom: -5rem;
            }

        }

        &.intro-bottom {
            height: calc(100vh - 10rem);
            margin-top: 10rem;
            background-color: var(--col-test-2);

            .intro-bottom-triangle {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 5rem solid transparent;
                border-right: 100vw solid var(--col-test-2);
                top: -5rem;
            }
        }

    }

    .para-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 4em;
        perspective: 200px;
    }

    .intro {
        background-color: rgba(58, 72, 85, 0.634);
        @include align-center();
        z-index: 10;
        height: 60vh;
    }

    .para-group {
        position: relative;
        // height: 100vh;
        transform-style: preserve-3d;

        &.para {
            position: absolute;
            inset: 0;
            top: 55rem;
        }

        .para-layer {
            height: 2200px;
            @include align-center();
            background-color: crimson;
            transform: translateZ(-100px) scale(2);

            img {
                position: absolute;
                inset: 0;
                // top: 0;
                height: 100%;
            }

            .Project-bg-blur {
                position: absolute;
                top: 0;
                z-index: 2;
                background-color: rgba(60, 56, 113, 0.224);
                width: 100%;
                height: calc(100%); 
                backdrop-filter:blur(3px) brightness(70%);      
            }
        }
    }


    h1 {
        position: relative;
        z-index: 2;
        margin: auto;
        padding: .3em;
        font-size: clamp(2rem, 3vw, 6rem);
        color: white;
        border-bottom: 3px solid white;
    }

    .Project-list {
        margin: 4rem auto 10rem auto;
        width: 100%;
    }


}



    // .Project-bg {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     overflow: hidden;

    //     .Project-bg-blur {
    //         position: absolute;
    //         top: 0;
    //         z-index: 2;
    //         background-color: rgba(60, 56, 113, 0.224);
    //         width: 100%;
    //         height: calc(100% + 10rem); 
    //         backdrop-filter:blur(3px) brightness(70%);      
    //     }

    //     .Project-code-img {
    //         z-index: 0;
    //         position: absolute;
    //         top: -3rem;
    //         left: -10rem;
    //         height: 180vh;
    //         height: calc(100% + 10rem); 
    //         opacity: .6;
    //         transform: rotate(0.002turn)
    //     }
    // }
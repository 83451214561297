:root {
    --width-triangle: 100px;
    --card-col-1: rgb(128, 67, 77);
    --card-col-2: rgb(78, 112, 133);
    --card-col-2-a: rgb(78, 112, 133, 0.5);
    --card-col-3: rgb(100, 66, 202);
}

.Project-card {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: var(--height-card);
    max-width: 80rem;
    margin: auto 1.3rem;
    background-color: var(--col-1);
    color: rgb(145, 145, 145);
    border-radius: 1rem;
    box-shadow: .5rem .5rem 1rem rgb(20, 20, 20);

    &:hover {
        cursor: pointer;
    }

    &:hover .PC-left {
        color: aliceblue;
    }

    &:hover .PC-right {
        color: aliceblue;
    }

    &:hover .PC-bg-img {
        transform: translateX(2rem);
    }

    &:hover .PC-right
    .PC-right-blur
      {
        opacity: 0;
    }

    &:hover .PC-right
    .PC-right-square
      {
        opacity: 1;
    }

    .PC-bg-img {
        position: absolute;    
        top: 0;
        left: 0;
        opacity: .6; 
        transition: transform var(--PC-animation-dur);
    }

    .PC-left {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 45%;
        height: 100%;
        z-index: 10;
        background-color: var(--card-col-1);
        border-left: 1rem solid var(--card-col-2);
        transition: color var(--PC-animation-dur);


        .PC-left-text {
            box-sizing: border-box;
            padding: 1rem 1rem;
            width: 100%;

            .PC-title {
                font-size: clamp(1.8rem, 2.5vw, 2.8rem);
                margin-bottom: 1rem;
                border-bottom: 2px solid black;
                padding-bottom: .3rem;
            }

            .PC-subtitle {
                color: rgb(20, 20, 20);
                font-size: 1rem;
                padding-left: .3rem;
            }
        }

        .PC-triangle {
            position: absolute;
            right: -5rem;
            width: 0;
            height: 0;
            border-top: var(--height-card) solid var(--card-col-1);
            border-right: 5rem solid transparent;
            z-index: 14;
            opacity: 1;
        }

        .PC-triangle-border {
            // display: none;
            box-sizing: content-box;
            position: absolute;
            right: -6rem;      
            width: 1rem;
            height: 0;
            border-top: var(--height-card) solid rgb(0, 0, 0);
            border-right: 5rem solid transparent;
            z-index: 5;      
            opacity: .5;
        }
    }

    .PC-right {
        box-sizing: border-box;
        position: relative;
        height: 100%;
        padding-left: 6rem;
        background-color: var(--card-col-2-a);
        z-index: 1;
        overflow: hidden;
        font-size: clamp(.8rem, 2.5vw, 1.3rem);

        .PC-right-blur {
            position: absolute;
            top: 0;
            height: 100%;
            padding: 1rem 3rem;
            text-shadow: 0 0 40px white;
            color: transparent;
            opacity: .96;
            transition: opacity var(--PC-animation-dur);
        }

        .PC-right-square {
            width: 100%;
            padding: 1rem 3rem;
            box-sizing: border-box;
            height: 100%;
            opacity: 0;
            transition: opacity var(--PC-animation-dur), color var(--PC-animation-dur);


            .PC-info-button {
                position: absolute;
                bottom: 1em;
                right: 2em;
                background-color: var(--card-col-1);
                font-size: clamp(.8rem, 2.5vw, 1rem);
                padding: 1em;
                user-select: none;
                border: 2px solid transparent;
                border-radius: 1rem;
                z-index: 100;
                opacity: 1; 

                &:hover {
                    border: 2px solid black;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 1000px) {

    :root {
        --height-card: 30rem
    }

    .Project-card {
        flex-direction: column;

        &:hover .PC-bg-img {
            transform: translateX(-2rem);
        }

        .PC-left {
            height: 40%;
            width: 100%;
            border-left: none;

            // .PC-left-text .PC-title {
            //     font-size: 2rem;
            // }
        }

        .PC-triangle {
            display: none;
        }

        .PC-right {
            padding-left: 0rem;
        }

    }
}
.Home {
    --introsection-height: 40rem;
    
    .Home-introsection{
        // height: var(--introsection-height);
        display: flex;
        flex-direction: row;

        .Home-introsection-img {
            width: 40%;

            .Home-cv-img {
                width: 100%;
                min-width: 20rem;
            }      
        }

        .Home-introsection-info {
            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
        }   
    }
    
}
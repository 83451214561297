.Footer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-top: 2rem;
    width: 100%;
    max-height: 14rem;
    left: 0;
    z-index: 40;
    background-color: var(--col-1);

    .Footer-triangle-top {
        width: 0;
        height: 0;
        border-top: 3rem solid transparent;
        border-left: 100vw solid var(--col-1);
        position: absolute;
        top: -3rem;
    }


    .Footer-center {
        display: flex;
        align-items: center;    
        flex-direction: column;
        height: 100%;
        padding-bottom: 1rem;
        color: white;
        font-size: clamp(.8rem, 2.5vw, 1.2rem);
        // font-style: italic;

        .Footer-dot {
            width: .5rem;
            height: .5rem;
            border-radius: 50%;
            background-color: rgb(119, 129, 164);
            margin: 0 1rem;
        }

        .Footer-info {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: blue;
        }
    }
}
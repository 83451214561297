.Skills {
    overflow-x: hidden;
    height: 100%;

    .Skills-container {
        width: 100%;

        .Skill-title {
            font-size: clamp(2rem, 3vw, 6rem);
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 50vh;
        }

        .SKills-OSBoxes {
            position: relative;
            max-width: 80rem;
            margin: auto;
            display: flex;
            height: 30vh;
            // background-color: green;
        }

        .Skills-languages {
            position: relative;
            max-width: 80rem;
            margin: auto;
            display: flex;
        }

        .Skill-outro {
            margin-top: 10rem;
            height: 50vh;
            width: 100%;
            background-color: teal;
        }
    }
}
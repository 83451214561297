:root {
    --PCC-color: rgba(24, 24, 24, 0.472);
    --PCC-color: transparent;
    --PCC-top-tri-height: 2rem;
    --PCC-bottom-tri-height: 2rem;
}

.Project-cardcontainer {  
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--PCC-color);
    width: 100%;
    padding: 2rem 0 2rem 0;
    margin-bottom: 3rem;
    z-index: 4;


    .PCC-triangle-top {
        width: 0;
        height: 0;
        border-top: var(--PCC-top-tri-height) solid transparent;
        border-right: 100vw solid var(--PCC-color);
        position: absolute;
        top: calc(-1 * var(--PCC-top-tri-height));
    }

    .PCC-triangle-bottom {
        width: 0;
        height: 0;
        border-top: var(--PCC-bottom-tri-height) solid var(--PCC-color);
        border-right: 100vw solid transparent;
        position: absolute;
        bottom: calc(-1 * var(--PCC-bottom-tri-height));
    }



}
.OSBoxes {
    margin: 1rem auto;
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border: 2px solid black;


    .OSBox {
        height: 6rem;
        // background-color: teal;
        width: 6rem;
        margin: 0 3rem;

        .OSBox-meter {
            margin-top: 1rem;
            height: 6rem;
            width: 6rem;
            // background-color: antiquewhite;
            position: relative;

            // border-radius: 100%;
            // border: 1rem solid black;

            .outer {
                height: 6rem;
                width: 6rem;
                border-radius: 50%;
                padding: .5rem;
                
                box-shadow: 6px 6px 10px -1px rgb(0, 0, 0, .15),
                    -6px -6px 10px -1px rgba(255, 255, 255, 0.7), ;
            }

            .inner {
                height: 5rem;
                width: 5rem;
                border-radius: 50%;
                box-shadow: inset 4px 4px 6px -1px rgb(0, 0, 0, .2),
                    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
                    -0.5px -0.5px 0px rgb(255, 255, 255, 1),
                    0.5px 0.5px 0px rgb(0, 0, 0, .15),
                    0px 12px 10px -10px rgb(0, 0, 0, .15);
            }
        }

        .circle {
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            position: absolute;
            top: 0;
            background: conic-gradient(rgb(61, 61, 165) 0deg 180deg, transparent 10deg 90deg);
            display: flex;
            align-items: center;
            justify-content: center;
            animation: 1s ease-out 0s 1 oscircle;
            transition: conic-gradient;

            .innercircle {
                width: 5rem;
                height: 5rem;
                background-color: white;
                border-radius: 50%;
            }
        }
    }

}

@keyframes oscircle {
    0% {    
        background: conic-gradient(rgb(61, 61, 165) 0deg 0deg, transparent 10deg 90deg);
    }

    100% {
        background: conic-gradient(rgb(61, 61, 165) 0deg 180deg, transparent 10deg 90deg);
    }
}